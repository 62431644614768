@import "@style/theme.scss";

.loader-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $z-index-loader;
    // border-radius: 0 0 7px 7px;


    background: transparent;
    // opacity: 0.9;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    & .loader {
        position: relative;
        $spinner-size: 50px;
        $container-size: $spinner-size + 10;
        $container-max-size: $container-size * 3;

        min-width: $container-size;
        min-height: $container-size;
        max-width: $container-max-size;
        max-height: $container-max-size;


        width: 90%;
        height: 90%;
        &.error {
            max-width: 90%;
            max-height: 90%;
            width: auto;
            height: auto;
        }

        background-color: rgb(255, 255, 255);
        box-shadow: 0px 8px 23px -3px rgba(0,0,0,0.68);
        border-radius: 20px;

        display: inherit;
        flex-flow: inherit;
        justify-content: inherit;
        align-items: inherit;

        & .spinner,
        & .spinner:after {
            border-radius: 50%;
            width: $spinner-size;
            height: $spinner-size;
        }
        & .spinner {
            $spinner-width: 4px;
            margin: 15px auto;
            font-size: 4px;
            position: relative;
            border-top: $spinner-width solid rgba(255, 255, 255, 0);
            border-right: $spinner-width solid rgba(255, 255, 255, 0);
            border-bottom: $spinner-width solid $bg-tertiary;
            border-left: $spinner-width solid $bg-tertiary;
            transform: translateZ(0);
            animation: spin 1.1s infinite linear;
        }
    }
}

.error-message {
    max-width: 90%;
}

.close {
    cursor: pointer;
    position: absolute;
    right: 14px;
    top: 12px;
    height: 10px;
    width: 10px;
    color: $fg-secondary;

    :global(body.embedded) & {
        display: none;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}